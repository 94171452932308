export class MapLoader {
  mapData: number[][];
  tileImages: { [key: number]: HTMLImageElement };
  imagesLoaded: boolean;

  constructor() {
    this.mapData = [];
    this.tileImages = {};
    this.imagesLoaded = false;

    this.loadMapData();
  }

  async loadMapData() {
    try {
      const response = await fetch(
        `${process.env.PUBLIC_URL}/WorldMaps/TestMap.json`
      );
      const mapJson = await response.json();
      this.mapData = this.shuffle2DArray(mapJson.mapData);
      await this.loadTileImages(mapJson.tileImages);
      this.imagesLoaded = true;
    } catch (e) {
      console.error("Error loading map data", e);
    }
  }
  async loadTileImages(tileImageUrls: { [key: number]: string }) {
    const promises = Object.entries(tileImageUrls).map(async ([key, url]) => {
      try {
        const response = await fetch(process.env.PUBLIC_URL + url);
        if (!response.ok) {
          throw new Error(`HTTP error! status: ${response.status}`);
        }
        const blob = await response.blob();
        const img = new Image();
        img.src = URL.createObjectURL(blob);

        return new Promise<void>((resolve, reject) => {
          img.onload = () => {
            console.log(`Image ${url} loaded successfully`);
            this.tileImages[parseInt(key)] = img;
            resolve();
          };
          img.onerror = (e) => {
            console.error(`Error loading image ${url}`, e);
            reject(e);
          };
        });
      } catch (e) {
        console.error(`Error fetching image ${url}`, e);
      }
    });

    await Promise.all(promises);
  }

  shuffle2DArray(array: number[][]): number[][] {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    for (let i = 0; i < array.length; i++) {
      for (let j = array[i].length - 1; j > 0; j--) {
        const k = Math.floor(Math.random() * (j + 1));
        [array[i][j], array[i][k]] = [array[i][k], array[i][j]];
      }
    }
    return array;
  }
}
